@import "/src/styles/mixin";

.wrap {
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 38;
  transform: translateY(-185%);
  transition: transform 0.2s ease;
  background-color: var(--white-color);

  &.isActive {
    transform: translateY(-5px);
    box-shadow: 0 20px 50px #1111111c;

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      transform: translateY(0);
      box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.09);
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      transform: translateY(-100px);
      box-shadow: unset;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    top: unset;
    bottom: 0;
    transform: translateY(185%);
    box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.09);
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: unset;
    transform: translateY(100%);
    background-color: transparent;
  }
}

.content {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  background-color: var(--white-color);

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    gap: 0;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--border-radius-small);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  }
}

.imageTitleWrap {
  display: flex;
  gap: 30px;
  align-items: center;
}

.title {
  @include font24;
  line-height: 40px !important;
  margin-bottom: 4px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 950px;

  &Wrap {
    @media only screen and (min-width: 1440px) and (max-width: 1919px) {
      max-width: 648px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1365px) {
      max-width: 538px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      max-width: 400px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.previewPic {
  width: 80px;
  height: 80px;

  @media only screen and (max-width: 1365px) {
    display: none;
  }
}

.price {
  @include font12;
  text-decoration: line-through;
  font-weight: 500;

  &Discount {
    @include font24;
    white-space: nowrap;
  }

  &Wrap {
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 100%;
    }
  }
}

.buttonWrap {
  flex-shrink: 0;
  width: 244px;

  @media only screen and (max-width: 1365px) {
    width: 108px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 140px;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 40px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
    gap: 16px;
    justify-content: space-between;
  }
}

.links {
  &Wrap {
    display: flex;
    gap: 16px;
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  &List {
    display: flex;
    gap: 16px;
  }

  &Link {
    @include font14;
    white-space: nowrap;
    color: #105ca8;
  }
}
