@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 70px;
  background-color: var(--white-color);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-small);
  z-index: 2;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    left: unset;
    right: 0;
  }
}

.link,
.button {
  @include font14;
  padding: 10px 40px;
  border-radius: var(--border-radius-small);
  transition: background-color 0.2s ease;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: var(--blue-background-color);
  }
}
