.title {
  max-width: 370px;
  margin-bottom: 24px;
}

.subtitle {
  margin-bottom: 36px;
}

.cabinet_content {
  display: flex;
  gap: 32px;

  .skeleton_cabinet {
    max-width: 420px;
    padding: 24px;
  }

  .skeleton_links {
    max-width: 382px;
    max-height: 146px;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .elem {
    width: 100%;
    max-width: 235px;
    height: 380px;
  }
}

.orders {
  margin-bottom: 24px;
}

.button {
  margin-bottom: 24px;
}
