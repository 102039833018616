.cardsList {
  display: grid;
  grid-template-columns: repeat(4, minmax(212px, 270px));
  gap: 20px;
  margin-bottom: 32px;

  @media only screen and (max-width: 1919.98px) {
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  }

  @media only screen and (max-width: 1439.98px) {
    grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
  }

  @media only screen and (max-width: 1365.98px) {
    grid-template-columns: repeat(auto-fill, minmax(209px, 1fr));
  }

  @media only screen and (max-width: 1023.98px) {
    grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));
  }

  @media screen and (max-width: 767.98px) {
    grid-template-columns: repeat(3, minmax(137px, 1fr));
  }

  @media screen and (max-width: 482.98px) {
    grid-template-columns: repeat(2, minmax(137px, 1fr));
    gap: 20px 10px;
    margin-bottom: 16px;
  }
}

//.card {
//  max-width: unset !important;
//  width: 23.9%;
//  @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
//    width: 32%;
//  }
//  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
//    width: 23.9%;
//  }
//  @media only screen and (min-width: 600px) and (max-width: 1023.98px) {
//    width: 31.9%;
//  }
//  @media screen and (max-width: 599.98px) {
//    width: 47.9%;
//  }
//}
