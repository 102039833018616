.wishlist {
  padding: 12px;

  .icon {
    transition: all 0.2s ease;

    path {
      stroke: var(--grey-darker-color);
    }

    &.active {
      fill: var(--grey-darker-color);
    }
  }
  &:hover {
    .icon {
      fill: var(--grey-darker-color);
    }
  }
}
