@import "/src/styles/mixin";

.wrap {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;

  .text {
    @include font14;
    color: var(--grey-color);
    @media screen and (max-width: 767px) {
      @include font12;
      font-weight: 500;
    }
  }

  .text,
  .code {
    @include font14;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    order: -1;
  }
  &:hover {
    .icon {
    }
  }
}
