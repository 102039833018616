@import "/src/styles/mixin";

.text {
  @include font14;
  margin-bottom: 36px;
  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    margin-bottom: 24px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 12px;
  }
}
.list {
  width: 100%;
  max-width: 702px;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    max-width: unset;
  }
}
.item {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }
  &Title {
    @include font16Bold;
    margin-bottom: 6px;
    width: 100%;
    grid-area: 1 / 1 / 2 / 2;
  }
  &Text {
    @include font14;
    grid-area: 2 / 1 / 3 / 2;
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
    padding: 20px 0;
    grid-template-rows: 1fr 50px;
  }
}
.switch {
  grid-area: 1 / 2 / 3 / 3;
}
